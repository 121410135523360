<template>
  <div v-if="!allowed">
    Not allowed
  </div>
  <FolderList v-else :resources="resources">
  </FolderList>
</template>


<script>
import FolderList from "@/components/FolderList.vue";

export default {
  name: "PublicResourceList",
  props: {
    allowed: Boolean,
    resources: Object,
  },
  components: {
    FolderList,
  },
  mixins: [],

  data() {
    return {
    };
  },

  mounted() { },

  methods: {},

  computed: {},

  watch: {},
};
</script>


<style lang='scss'></style>